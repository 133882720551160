import { createHigherOrderComponent } from '@wordpress/compose'
import {Fragment, useState} from "react";
import {InspectorControls} from "@wordpress/block-editor";
import {PanelBody} from "@wordpress/components";
import {__} from '@wordpress/i18n'
import {useEffect} from 'react'
import { SelectControl } from '@wordpress/components'

export function addFontSelectionPanel(blockTypes) {
    return createHigherOrderComponent(BlockEdit => {
        return props => {
            if ( ! blockTypes.includes(props.name)) {
                return <BlockEdit {...props}/>
            }

            const [loaded, setLoaded] = useState(false)
            const [availableFonts, setAvailableFonts] = useState([])

            useEffect(() => {
                fetch('/wp-json/plugin/sfat-webfonts/v1/fonts')
                    .then(res => res.json())
                    .then(fonts => {
                        setAvailableFonts(fonts)
                        setLoaded(true)
                    })
                    .catch(err => {
                        console.error(err)
                        setLoaded(true)
                    })
            }, [])

            const options = availableFonts.length ? [
                { value: null, label: '--' },
                ...availableFonts.map(name => ({value: name, label: name}))
            ] : []

            const styles = {}
            if (props.attributes.sfWebfontName && props.attributes.sfWebfontName !== '--') {
                styles.fontFamily = props.attributes.sfWebfontName
            }

            return (
                <Fragment>
                    <BlockEdit {...props} style={styles}/>

                    {blockTypes.includes(props.name) &&
                        <InspectorControls>
                            <PanelBody title={__('Font family', 'sfat-webfonts')}>
                                {loaded &&
                                    <select onChange={e => props.setAttributes({ sfWebfontName: e.target.value })}>
                                        {options.map(o =>
                                            <option key={o.value}
                                                    value={o.value}
                                                    style={{fontFamily: o.value}}
                                                    selected={o.value === props.attributes.sfWebfontName}>
                                                {o.label}
                                            </option>
                                        )}
                                    </select>
                                    // <SelectControl
                                    //     options={ options }
                                    //     value={ props.attributes.sfWebfontName }
                                    //     onChange={sfWebfontName => props.setAttributes({ sfWebfontName })}
                                    // />
                                ||
                                    __('Loading', 'sfat-webfonts') + '&hellip;'
                                }
                            </PanelBody>
                        </InspectorControls>
                    }
                </Fragment>
            )
        }
    }, 'withFontSelection')
}

