export function registerAttributes(blockTypes) {
    return (settings) => {
        if (blockTypes.includes(settings.name)) {
            settings.attributes = {
                ...settings.attributes,
                sfWebfontName: {
                    type: 'string',
                    default: null
                }
            }
        }
        return settings
    }
}
