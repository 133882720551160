import '../scss/plugin.scss'

import { addFilter } from '@wordpress/hooks'

import applyExtraClass from "./addClass.js";
import {registerAttributes} from "./register.js";
import {addFontSelectionPanel} from "./panel.js";

const blockTypes = [
    'core/heading'
]

addFilter('blocks.getSaveContent.extraProps', 'sfat-webfonts/add-extra-class', applyExtraClass)
addFilter('blocks.registerBlockType', 'sfat-webfonts/register', registerAttributes(blockTypes))
addFilter('editor.BlockEdit', 'sfat-webfonts/add-panel', addFontSelectionPanel(blockTypes))
